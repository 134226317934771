import CryptoJS from "crypto-js";

const CRYPTO_KEY = "IMFINESDCRYPTO";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function encryptText(value: string) {
  const cipher = CryptoJS.AES.encrypt(value, CRYPTO_KEY).toString();
  return cipher;
}

export function decryptText(value: string) {
  var bytes = CryptoJS.AES.decrypt(value, CRYPTO_KEY);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export const dlog = (...args: Array<any>) => {
  console.log(
    "%c%s",
    "color: black; background: steelblue;",
    "IMFINE",
    ...args
  );
};

export function removeSpecialCharacter(str: string) {
  var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\" ]/gi;
  if (reg.test(str)) {
    return str.replace(reg, "");
  } else {
    return str;
  }
}
