export enum EVENT_TYPE {
  EVENT_TEST = 'EVENT_TEST',
  EVENT_EMOJI = 'EVENT_EMOJI',
  EVENT_DIALOG = 'EVENT_DIALOG',
  EVENT_CLOSE_LIVE = 'EVENT_CLOSE_LIVE',
  EVENT_SHOW_QUIZ = 'EVENT_SHOW_QUIZ'
}

export const emitEvent = (event_name: EVENT_TYPE, arg?: object) => {
  document.dispatchEvent(new CustomEvent(event_name, { detail: arg }))
}

export const onEvent = (
  event_name: EVENT_TYPE,
  listener: EventListenerOrEventListenerObject,
) => {
  document.addEventListener(event_name, listener)
}

export const removeEvent = (
  event_name: EVENT_TYPE,
  listener: EventListenerOrEventListenerObject,
) => {
  document.removeEventListener(event_name, listener)
}
