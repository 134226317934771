import axios, { AxiosRequestConfig } from "axios";
import { dlog } from "../../sdlib/sdutil";

/**
 * API 기본 주소
 * 디버그모드인 경우에는 local 서버를 바라보게끔 설정
 */
const url =
  process.env.NODE_ENV === "development" ? "https://cmw.im-fine.dev" : "";

function encode(value: string) {
  return encodeURIComponent(value);
}

export function getServerVersion() {
  return axios.post(`${url}/api/Version`);
}

export function uploadFile(
  name: string,
  comment: string,
  file: File | null,
  onProgress: (percentage: number) => void
) {
  dlog(file);
  return axios.post(`${url}/FileUpload/upload_post`, file, {
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": "multipart/form-data",
      use_url_encoded: "true",
      user_name: encode(name),
      user_comment: encode(comment),
      user_filename: encode(file?.name || "empty.jpg"),
    },
    onUploadProgress: (e) => {
      let percentage = Math.floor((e.loaded / e.total) * 100);
      onProgress(percentage);
    },
  });
}

export function uploadFile2(
  name: string,
  comment: string,
  file: File,
  onProgress: (percentage: number) => void
) {
  let xhr = new XMLHttpRequest();

  let form_data = new FormData();
  form_data.append("name", name);
  form_data.append("comment", comment);
  form_data.append("data", file);

  xhr.upload.onprogress = (evt) => {
    var complete = ((evt.loaded / evt.total) * 100) | 0;
    dlog(evt.loaded, evt.total, complete);
  };

  xhr.open("POST", `${url}/FileUpload/upload_multipart`, true);
  xhr.send(form_data);
}
