import React, { HTMLAttributes } from "react";
import { dlog } from "../sdlib/sdutil";
import styles from "../styles/InputComponent.module.css";

type InputComponentType = {
  label: string;
  multiline?: boolean;
  onChangeInput: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  name: string;
  maxLength?: number;
  error: boolean;
};

function InputComponent({
  label,
  multiline,
  name,
  value,
  error,
  maxLength,
  onChangeInput,
  ...props
}: HTMLAttributes<HTMLDivElement> & InputComponentType) {
  return (
    <div className={styles.root} {...props}>
      <div className={styles.label}>{label}</div>
      <div
        className={multiline ? styles.multi_border : styles.border}
        style={{ borderColor: error ? "red" : "rgba(0, 0, 0, 0.12)" }}
      >
        <textarea
          maxLength={maxLength}
          name={name}
          value={value}
          onChange={onChangeInput}
          wrap={multiline ? "on" : "off"}
          className={multiline ? styles.multi_input : styles.input}
        />
      </div>
    </div>
  );
}

export default InputComponent;
