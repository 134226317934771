import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";
import axios from "axios";
import { dlog } from "../sdutil";
import { emitEvent, EVENT_TYPE } from "../event";

let firebase_config: FirebaseConfig;
let firebase_token: string | null = null;
let firebase_db_password: string;

export const CHAT_PATH = "chat_data";
export const ADMIN_PATH = "admin_data";

type FireConfigResponse = {
  code: number;
  data: FirebaseConfig;
};

type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  key?: string;
  usePublicVapidKey?: string;
  databaseURL?: string;
};

export enum MessageType {
  NOTIFICATION = "NOTICATION", // TODO :: 오타
  HIGHLIGHT = "HIGHLIGHT",
  HIGHLIGHT_COMPLETE = "HIGHLIGHT_COMPLETE",
  LOGIN_FAIL = "LOGIN_FAIL",
  BLOCK = "BLOCK",
  REFRESH = "REFRESH",
  CLOSE = "CLOSE",
}

export function initializeFirebase(config: FirebaseConfig) {
  dlog("initialize Firebase");

  firebase_config = config;
  initializeMessage();
}

export function initializeFirebaseAnalystic(config: FirebaseConfig) {
  firebase_config = config;
  firebase.initializeApp(firebase_config);
  firebase.analytics();
}

function initializeMessage() {
  if (!firebase.messaging.isSupported()) return;

  firebase.initializeApp(firebase_config);
  const messaging = firebase.messaging();

  if (firebase_config.usePublicVapidKey != null) {
    messaging.usePublicVapidKey(firebase_config.usePublicVapidKey);
  }

  const updateToken = (token: string) => {
    firebase_token = token;
    if (firebase_config.key != null) {
      subscribeTopic(firebase_config.key, token);
    }
  };

  const showError = (err: any) => {
    console.log("An error occurred while retrieving token. ", err);
  };

  messaging
    .requestPermission()
    .then(() => {
      messaging
        .getToken()
        .then((currentToken) => {
          updateToken(currentToken);
        })
        .catch((err) => {
          showError(err);
        });
    })
    .catch((err) => {
      showError(err);
    });

  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then((refreshToken) => {
        updateToken(refreshToken);
      })
      .catch((err) => {
        showError(err);
      });
  });

  messaging.onMessage((payload) => {
    const type = payload.data.type || MessageType.NOTIFICATION;
    if (type === MessageType.NOTIFICATION) {
      const title = payload.data.title;
      const options = {
        body: payload.data.body,
        icon: "/img/icons/android-chrome-192x192.png",
        requireInteraction: true,
        silent: false,
        actions: [
          { action: "close", title: "CLOSE" },
          { action: "open", title: "OPEN" },
        ],
      };

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((swreg) => {
          swreg.showNotification(title, options);
        });
      }
    } else if (
      type === MessageType.HIGHLIGHT ||
      type === MessageType.HIGHLIGHT_COMPLETE ||
      type === MessageType.BLOCK
    ) {
      emitEvent(EVENT_TYPE.EVENT_DIALOG, payload.data);
    }
  });
}

function subscribeTopic(server_key: string, token: string) {
  const topic = "/topics/CONVENTION";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `key=${server_key}`,
  };

  const data = {
    to: topic,
    registration_tokens: [token],
  };

  dlog("SUBSCRIBE TOPIC");

  axios.post("https://iid.googleapis.com/iid/v1:batchAdd", data, {
    headers: headers,
  });
}

export function getFCMToken() {
  return firebase_token;
}

export async function loadFirebaseConfig() {
  const response = await axios.post<FireConfigResponse>(
    "https://imfine.cf/api/service/dbinfo"
  );
  return response.data.data;
}

export function addData(path: string, value: Object, isUpdateTime = false) {
  const data = isUpdateTime
    ? {
        ...value,
        time: {
          ".sv": "timestamp",
        },
      }
    : value;

  return axios.post(`${firebase_config.databaseURL}${path}.json`, data, {
    params: {
      ...getAuthParam(),
    },
  });
}

export function updateData(path: string, value: Object) {
  return axios.patch(`${firebase_config.databaseURL}${path}.json`, value, {
    params: {
      ...getAuthParam(),
    },
  });
}

export function getData(path: string, params: object | null = null) {
  return axios.get(`${firebase_config.databaseURL}${path}.json`, {
    params: {
      ...getAuthParam(),
      ...params,
    },
  });
}

export function setDBPassword(payload: string) {
  firebase_db_password = payload;
}

export function loadMessages(count: number) {
  return getData(CHAT_PATH, {
    orderBy: `"time"`,
    limitToLast: count,
  });
}

export function updateMessagesByTime(path: string, timestamp: number) {
  const time = timestamp + 1;
  return getData(path, {
    orderBy: `"time"`,
    startAt: time,
  });
}

function getAuthParam() {
  return {
    auth: firebase_db_password,
  };
}
