import React, { RefObject, useEffect, useRef, useState } from "react";
import logo from "../assets/imcommunity_wall_logo.png";
import styles from "../styles/Upload.module.css";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import InputComponent from "../components/InputComponent";
import { dlog, removeSpecialCharacter } from "../sdlib/sdutil";
import { getServerVersion, uploadFile } from "../api/rest";
import { drawImage, resizeImage } from "../lib/resize";

type UploadType = {
  name: string;
  comment: string;
  file: File | null;
  error: boolean;
};

function Upload() {
  const fileRef: RefObject<HTMLInputElement> = useRef(null);
  const canvasRef: RefObject<HTMLCanvasElement> = useRef(null);

  const [version, setVersion] = useState({
    server: "",
    react: process.env.REACT_APP_VERSION_CODE,
  });

  const [data, setData] = useState<UploadType>({
    name: "",
    comment: "",
    file: null,
    error: false,
  });

  const [open, setOpen] = useState(false);

  const [progress, setProgress] = useState({
    value: 100,
    active: false,
  });

  const { name, comment, file, error } = data;

  const onLoadImage = () => {
    dlog("load");

    fileRef.current?.click();
  };

  const onReadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    dlog(files);
    if (files != null && files.length > 0) {
      dlog(files[0]);

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = function () {
          if (canvasRef != null) {
            const canvas = canvasRef.current as HTMLCanvasElement;
            drawImage(canvas, img);
          }
        };
      };

      reader.onloadend = () => {
        setData({
          ...data,
          file: files[0],
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onSend = async () => {
    dlog("Send Data");

    setData({
      ...data,
      error: false,
    });
    setProgress({
      value: 0,
      active: true,
    });

    const onProgreess = (percentage: number) => {
      dlog("percentage : ", percentage);

      setProgress({
        ...progress,
        active: true,
        value: percentage,
      });
    };

    if (data.file != null) {
      const settings = {
        file: data.file,
        maxSize: 1024,
        backgroundColor: "#ff000000",
      };
      const resizeFile = (await resizeImage(settings)) as File;
      let file = new File([resizeFile as BlobPart], data.file.name, {
        type: resizeFile.type,
      });
      uploadFile(name, comment, file, onProgreess)
        .then((r) => {
          setTimeout(() => {
            setProgress({
              value: 100,
              active: false,
            });
            setData({
              ...data,
              error: false,
              file: null,
              name: "",
              comment: "",
            });
          }, 500);
        })
        .catch((err) => {
          dlog(err, err.response);
          setProgress({
            value: 100,
            active: false,
          });
        });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (value.length <= 32) {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    let update_value = removeSpecialCharacter(value);

    if (value.length <= 32) {
      setData({
        ...data,
        [name]: update_value,
      });
    }
  };

  useEffect(() => {
    getServerVersion().then((result) => {
      const v = result.data.version.version;

      setVersion({
        ...version,
        server: v,
      });
    });
  }, []);

  const onShowDialog = () => {
    if (name.length > 0 && comment.length > 0 && data.file != null) {
      setOpen(true);
    } else {
      setData({
        ...data,
        error: true,
      });
    }
  };

  const onClickDialog = (state: boolean) => {
    setOpen(false);
    if (state) {
      setTimeout(onSend, 2000);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          사진을 업로드 하시겠습니까?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => onClickDialog(false)}
            color="primary"
            style={{ color: "black" }}
          >
            Disagree
          </Button>
          <Button
            onClick={() => onClickDialog(true)}
            color="primary"
            autoFocus
            style={{ color: "black" }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <div className={styles.root}>
        <div className={styles.contents}>
          <img id="logo_img" src={logo} className={styles.logo} />
          <div className={styles.version}>
            Build ({version.react}, {version.server})
          </div>
          <div className={styles.camera_base}>
            <ButtonBase onClick={onLoadImage}>
              <div
                className={styles.camera_box}
                style={{
                  borderColor: error && file == null ? "red" : "#707070",
                }}
              >
                {file != null && (
                  <canvas ref={canvasRef} className={styles.load_image} />
                )}
                <CameraIcon className={styles.camera_icon} />
              </div>
              <input
                accept="image/*"
                ref={fileRef}
                onChange={onReadFile}
                type="file"
                style={{ display: "none" }}
              />
            </ButtonBase>
          </div>

          <InputComponent
            error={error && name.length == 0}
            className={styles.name_field}
            label="Name"
            onChangeInput={onChangeName}
            name="name"
            value={name}
          />
          <InputComponent
            error={error && comment.length == 0}
            className={styles.comment_field}
            label="Comment"
            onChangeInput={onChange}
            name="comment"
            value={comment}
            multiline
          />
          <div className={styles.comment_length}>({comment.length}/32)</div>
          <ButtonBase
            className={styles.send_button}
            onClick={onShowDialog}
            disabled={progress.active}
          >
            <div className={styles.send_child}>
              <div className={styles.send_contents}></div>
              <div
                className={styles.progress}
                style={{ width: `${progress.value}%` }}
              ></div>
              <div className={styles.send_label}>
                {progress.active ? `${progress.value}%` : "SEND"}
              </div>
            </div>
          </ButtonBase>
        </div>
      </div>
    </>
  );
}

export default Upload;
