import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeFirebaseAnalystic } from "./sdlib/firebase";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.NODE_ENV !== "development") {
  var firebaseConfig = {
    apiKey: "AIzaSyC7MoaS6kukBdc9sg59FU5_qp5UQCmucUc",
    authDomain: "imcommunitywall.firebaseapp.com",
    projectId: "imcommunitywall",
    storageBucket: "imcommunitywall.appspot.com",
    messagingSenderId: "195433104506",
    appId: "1:195433104506:web:41e821354f0bef88dce7c3",
    measurementId: "G-WXS5CK4R27",
  };
  initializeFirebaseAnalystic(firebaseConfig);
}
