import { dlog } from "../../sdlib/sdutil";

type ImageSetting = {
  file: File;
  maxSize: number;
  backgroundColor: string;
};

const dataURItoBlob = (dataURI: string) => {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? window.atob(dataURI.split(",")[1])
      : window.unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ia], { type: mime });
};

export function drawImage(
  canvas: HTMLCanvasElement,
  img: HTMLImageElement,
  maxSize: number = 0
) {
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;

  let { width, height } = img;
  const isWide = width > height;

  if (isWide) {
    const size = maxSize > 0 && width > maxSize ? maxSize : width;
    canvas.width = size;
    canvas.height = size;

    const left = (width - height) / 2;
    context.drawImage(img, left, 0, height, height, 0, 0, size, size);
  } else {
    const size = maxSize > 0 && height > maxSize ? maxSize : height;
    canvas.width = size;
    canvas.height = size;

    const left = (height - width) / 2;
    context.drawImage(img, 0, left, width, width, 0, 0, size, size);
  }

  // const logo = document.getElementById("logo_img");
  // context.drawImage(logo as any, 10, 10);

  return context;
}

export function resizeImage(setting: ImageSetting) {
  const fr = new FileReader();
  const img = new Image();

  const { file, maxSize, backgroundColor } = setting;

  const resize = () => {
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    let { width, height } = img;

    canvas.setAttribute("id", "canvas");
    const context = drawImage(canvas, img, maxSize);

    const data = context.getImageData(0, 0, width, height);
    const compositeOperation = context.globalCompositeOperation;
    context.globalCompositeOperation = "destination-over";
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, width, height);

    const imageData = canvas.toDataURL(file.type);
    context.clearRect(0, 0, width, height);
    context.putImageData(data, 0, 0);

    context.globalCompositeOperation = compositeOperation;

    return dataURItoBlob(imageData);
  };

  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(
        new Error("VImageInput# Problem resizing image: file must be an image.")
      );
    }

    fr.onload = (readerEvent) => {
      img.onload = () => resolve(resize());
      img.src = readerEvent?.target?.result as string;
    };

    fr.readAsDataURL(file);
  });
}
